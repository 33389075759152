import { useNavigation, useNotification, useUpdate } from "@pankod/refine-core";
import {
  Button,
  DeleteButton,
  useModal,
  Modal,
  Select,
  Row,
  Col,
  Form,
  EditButton,
} from "@pankod/refine-antd";
import { ISession } from "interfaces";
import { useState } from "react";
import { flags } from "../../assets/skills";
import axios from "axios";

type SessionActionProps = {
  record: ISession;
};

const { REACT_APP_MEDIA_URL } = process.env;

export const SessionActions: React.FC<SessionActionProps> = ({ record }) => {
  const { open } = useNotification();
  const { replace } = useNavigation();

  const { mutate } = useUpdate<ISession>();
  const { modalProps, show, close } = useModal();

  const [workingOnit, setWorkingOnIt] = useState<Boolean>(false);

  const [reason, setReason] = useState<string>("Infringing copyright");

  const takeDownorRestore = () => {
    close();
    setWorkingOnIt(true);
    mutate(
      {
        id: String(record?._id),
        resource: "sessions",
        values: {
          visibility: record?.isAdminApproved ? false : true,
          reason: reason,
        },
        successNotification: false,
        mutationMode: "optimistic",
      },
      {
        onSuccess: () => {
          setWorkingOnIt(false);
          open?.({
            type: "success",
            message: `${
              record?.isAdminApproved
                ? "Session has been taken down"
                : "Session has been restored"
            }`,
            description: "Session Suspension",
            key: record?._id,
          });
        },
      }
    );
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div>
        <Button
          disabled={workingOnit === true}
          style={{
            borderColor: !record?.isAdminApproved ? "#67be23" : "#FFA500",
            color: !record?.isAdminApproved ? "#67be23" : "#FFA500",
          }}
          onClick={record?.isAdminApproved ? show : takeDownorRestore}
        >
          {record?.isAdminApproved ? "Take Down" : "Restore"}
        </Button>
      </div>
      <div style={{ paddingLeft: 10 }}>
        <EditButton hideText size='small' recordItemId={record?._id} />
      </div>
      <div style={{ paddingLeft: 10 }}>
        <DeleteButton
          hideText
          size='small'
          recordItemId={record?._id}
          onSuccess={async (value: any) => {
            // return console.log(value);

            if (!value?.data?.canDelMedia) return;

            let thumb = value?.data?.thumbnail?.split("/").pop();
            let pitch = value?.data?.videoPitch?.split("/").pop();

            await axios.delete(
              `${REACT_APP_MEDIA_URL}/media/${thumb}?folderName=thumbnails`
            );

            await axios.delete(
              `${REACT_APP_MEDIA_URL}/media/${pitch}?folderName=pitches`
            );

            replace("/sessions");
          }}
        />
      </div>

      <Modal
        title={"Reason For Taking Down Post"}
        onOk={takeDownorRestore}
        {...modalProps}
      >
        <Row>
          <Col span={24}>
            <Form.Item name={"reason"}>
              <Select
                defaultValue='violation of terms'
                onChange={(e) => setReason(e)}
              >
                {flags.map((flag) => (
                  <Select.Option key={flag} value={flag}>
                    {flag}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};
