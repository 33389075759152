import { Row, Col, Card, Typography } from '@pankod/refine-antd';
import { NewTeachers } from 'components/dashboard/newTeachers';
import { RecentLogins } from 'components/dashboard/recentLogins';
import { Totals } from 'components/dashboard/totals';
// import {
//   TotalCoaches,
//   TotalLesns,
//   TotalStuds,
// } from 'components/dashboard/topTabs';
// import {
//   Newest,
//   RecentLogins,
//   TripTab,
//   CruiseTab,
//   InsuranceTab,
//   FlightTab,
// } from "components/dashboard";

const { Text } = Typography;

export const DashboardPage: React.FC = () => {
  return (
    <Row gutter={[16, 16]}>
      <Totals />
      <Col xl={17} lg={16} md={24} sm={24} xs={24}>
        <Card
          bodyStyle={{
            height: 280,
            padding: 0,
          }}
          title={
            <Text strong /* style={{ fontSize: 24, fontWeight: 800 }} */>
              Newest Teachers
            </Text>
          }
        >
          <NewTeachers />
        </Card>
      </Col>
      <Col xl={7} lg={8} md={24} sm={24} xs={24}>
        <Card
          bodyStyle={{
            height: 280,
            overflowY: 'scroll',
          }}
          title={<Text strong>Recent Logins</Text>}
        >
          <RecentLogins />
        </Card>
      </Col>
    </Row>
  );
};
