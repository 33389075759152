const categories = [
  {
    label: "Fitness",
    value: "Fitness",
  },
  {
    label: "Art",
    value: "Art",
  },
  {
    label: "Music",
    value: "Music",
  },
  {
    label: "Excel",
    value: "Excel",
  },
  {
    label: "DIY",
    value: "DIY",
  },
  {
    label: "Piano",
    value: "Piano",
  },
  {
    label: "Science",
    value: "Science",
  },
  {
    label: "Math",
    value: "Math",
  },
  {
    label: "Law",
    value: "Law",
  },
  {
    label: "Money",
    value: "Money",
  },
  {
    label: "Language",
    value: "Language",
  },
  {
    label: "Love",
    value: "Love",
  },
  {
    label: "Wellness",
    value: "Wellness",
  },
  {
    label: "Recipies",
    value: "Recipies",
  },
  {
    label: "Yoga",
    value: "Yoga",
  },
  {
    label: "Sports",
    value: "Sports",
  },
];

const flags = [
  "Violation of terms",
  "Infringing copyright",
  "Violent or repulsive content",
  "Hateful or abusive content",
  "Sexual content",
  "Harmful dangerous acts",
  "Child abuse",
  "Promoting terrorism",
  "Spam or misleading",
];

export {categories, flags};
