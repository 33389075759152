import { Form, Row, Col, Checkbox, Input } from "@pankod/refine-antd";
import { categories } from "../../assets/skills";
const { TextArea } = Input;

export const Skills: React.FC = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="What categories of skills does the teacher/coach want to share?"
            name="subjects"
            rules={[
              {
                required: false,
                message: "Choose at least 1 skill",
              },
            ]}
          >
            <Checkbox.Group options={categories} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Write a general bio about the teacher/coach that tells potential
            participants of the skills in the fields they
            will be sharing"
            name="bio"
            rules={[
              {
                max: 500,
                required: true,
                message: "Bio is required",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("bio").length >= 140) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    <span style={{ color: "red" }}>
                      Bio must be at least 140 characters
                    </span>
                  );
                },
              }),
            ]}
          >
            <TextArea rows={5} placeholder="Teacher's Bio" maxLength={500} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
