import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  // Upload,
  // getValueFromEvent,
  Col,
  DatePicker,
  Edit,
  Form,
  // getValueFromEvent,
  Input,
  Row,
  Select,
  // Upload,
  useForm,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ISession } from "interfaces";
import timezones from "assets/timezones";
import { categories } from "assets/skills";
import languages from "assets/languages";

dayjs.extend(utc);
dayjs.extend(timezone);

export const SessionsEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<ISession>();

  const { TextArea } = Input;

  const sessionInfo = queryResult?.data?.data;

  // used spread operator below since you can't destructure obj if a key of the obj is undefined -> coz data is still fetching
  // const _session = {
  //   ...formProps?.initialValues,
  // };

  // const { sessionDate, ...otherValues } = _session;

  // const daita = {
  //   // all this just to modify the session date to append time to it
  //   sessionDate: new Date(formProps?.initialValues?.sessionDate).setHours(
  //     formProps?.initialValues?.startTime?.split(':')[0],
  //     formProps?.initialValues?.startTime?.split(':')[1]
  //   ),
  //   ...otherValues,
  // };

  // console.log(daita);

  // const { REACT_APP_MEDIA_URL } = process.env;

  // let initialImage = [
  //   {
  //     uid: `${sessionInfo?.thumbnail}`,
  //     name: `${sessionInfo?.title}`,
  //     url: sessionInfo?.thumbnail,
  //     type: `image/${sessionInfo?.thumbnail?.split(".").pop()}`,
  //     size: 70922,
  //     percent: 100,
  //     // "status": "done"
  //   },
  // ];
  // let initialVideo = [
  //   {
  //     uid: `${sessionInfo?.thumbnail}`,
  //     name: `${sessionInfo?.title}`,
  //     url: sessionInfo?.videoPitch,
  //     // type: `video/${sessionInfo?.videoPitch?.split('.').pop()}`,
  //     type: `video/mp4`,
  //     size: 70922,
  //     percent: 100,
  //     // "status": "done"
  //   },
  // ];

  return (
    <Edit
      title={`Edit ${sessionInfo?.title || ""}`}
      saveButtonProps={saveButtonProps}
      isLoading={queryResult?.isFetching}
      pageHeaderProps={{
        extra: null,
      }}
    >
      {!queryResult?.isFetching && queryResult?.status === "success" && (
        <Form
          // initialValues={daita}
          // form={formProps.form}
          // onValuesChange={formProps.onValuesChange}
          // onKeyUp={formProps.onKeyUp}
          // onFinish={formProps.onFinish}
          {...formProps}
          layout='vertical'
        >
          {queryResult?.isSuccess && (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label='Title'
                    name='title'
                    rules={[
                      {
                        required: true,
                        message: "Title is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  {sessionInfo?.recurringDetails ? (
                    <Form.Item label='Session Date'>
                      <Input disabled value={"Recurring"} />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label='Session Date'
                      name='dateTime'
                      getValueProps={(value) => ({
                        value: value
                          ? dayjs(value).tz(sessionInfo?.timeZone)
                          : "",
                      })}
                      rules={[
                        {
                          required: true,
                          message: "Session Date is required",
                        },
                      ]}
                    >
                      <DatePicker
                        disabledDate={(d) => !d || d.isBefore(new Date())}
                        showTime={{
                          format: "HH:mm",
                        }}
                        format='YYYY-MM-DD HH:mm'
                        onChange={(_, dateString) => console.log(dateString)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}
                </Col>

                <Col span={12}>
                  <Form.Item
                    label='Session Cost (USD)'
                    name='cost'
                    rules={[
                      {
                        required: true,
                        message: "Cost is required",
                      },
                    ]}
                  >
                    <Input type='number' />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label='Time Zone'
                    name='timeZone'
                    rules={[
                      {
                        required: true,
                        message: "Timezone is required",
                      },
                    ]}
                  >
                    <Select
                      options={timezones.map((t) => ({
                        label: t.name,
                        value: t.id,
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label='Session Category'
                    name='category'
                    rules={[
                      {
                        required: true,
                        message: "Category is required",
                      },
                    ]}
                  >
                    <Select
                      mode='multiple'
                      allowClear
                      options={categories.map((t) => ({
                        label: t.label,
                        value: t.value,
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label='Language of Instruction'
                    name='language'
                    rules={[
                      {
                        required: true,
                        message: "Language of Instruction is required",
                      },
                    ]}
                  >
                    <Select
                      options={languages.map((t) => ({
                        label: t.name,
                        value: t.name,
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={6}>
                  <Form.Item
                    label='Participant Size'
                    name='capacity'
                    rules={[
                      {
                        required: true,
                        message: "Participant Size is required",
                      },
                    ]}
                  >
                    <Select
                      options={[
                        ...["Unlimited"],
                        ...Array.from(Array(10).keys()),
                      ].map((x: any) => ({
                        label: typeof (x + 1) === "number" ? (x + 1) * 10 : x,
                        value: typeof (x + 1) === "number" ? (x + 1) * 10 : x,
                      }))}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label='What will participants come out with from this session:'
                    name='description'
                    rules={[
                      {
                        required: true,
                        message: "Description is required",
                      },
                    ]}
                  >
                    <TextArea rows={5} maxLength={500} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label='Handouts'
                    name='handouts'
                    rules={[
                      {
                        required: true,
                        message: "Handouts are required",
                      },
                    ]}
                  >
                    <TextArea rows={5} maxLength={500} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    label='Resources'
                    name='resources'
                    rules={[
                      {
                        required: true,
                        message: "Resources are required",
                      },
                    ]}
                  >
                    <TextArea rows={5} maxLength={500} />
                  </Form.Item>
                </Col>

                {/* <Col span={12}>
                  <Form.Item label='Video Pitch'>
                    <Form.Item
                      name='videoPitch'
                      // valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      noStyle
                    >
                      <Upload.Dragger
                        defaultFileList={initialVideo}
                        name='myFile'
                        accept='video/mp4,video/x-m4v,video/*'
                        action={`${REACT_APP_MEDIA_URL}/media/upload`}
                        listType='picture'
                        multiple={false}
                        maxCount={1}
                      >
                        <p className='ant-upload-text'>
                          Drag & drop a file in this area
                        </p>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label='Thumbnail'>
                    <Form.Item
                      name='thumbnail'
                      // valuePropName="fileList"
                      getValueFromEvent={getValueFromEvent}
                      noStyle
                    >
                      <Upload.Dragger
                        defaultFileList={initialImage}
                        name='myFile'
                        accept='image/*'
                        action={`${REACT_APP_MEDIA_URL}/media/upload/thumbnail`}
                        listType='picture'
                        multiple={false}
                        maxCount={1}
                        onChange={(file) => {
                          console.log(file?.file?.response);
                          return file?.file?.response;
                        }}
                      >
                        <p className='ant-upload-text'>
                          Drag & drop a file in this area
                        </p>
                      </Upload.Dragger>
                    </Form.Item>
                  </Form.Item>
                </Col> */}
              </Row>
            </>
          )}
        </Form>
      )}
    </Edit>
  );
};

/*{
   <Form.Item label="Video Pitch">
<Form.Item
  name="image"
  valuePropName="fileList"
  getValueFromEvent={getValueFromEvent}
  noStyle
>
  <Upload.Dragger
    name="file"
    action={`${apiUrl}/media/upload`}
    listType="picture"
    multiple={false}
    maxCount={1}
  >
    <p className="ant-upload-text">
      Drag & drop a file in this area
    </p>
  </Upload.Dragger>
</Form.Item>
</Form.Item> 
}*/
// https://github.com/ant-design/ant-design/issues/5146
