import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  Create,
  Form,
  Button,
  SaveButton,
  useStepsForm,
  Steps,
} from "@pankod/refine-antd";

import { PersonalInfo, Skills } from "components/NewCoach";

const { Step } = Steps;

export const TeacherCreate: React.FC<IResourceComponentsProps> = () => {
  const { current, gotoStep, stepsProps, formProps, saveButtonProps } =
    useStepsForm();

  const onFinish = (values: any) => {
    console.log("Success:", values);
  };

  const formList = [
    <>
      <PersonalInfo />
    </>,
    <>
      <Skills />
    </>,
  ];

  return (
    <Create
      // resource="allagents"
      saveButtonProps={saveButtonProps}
      title="New Teacher"
      actionButtons={
        <>
          {current > 0 && (
            <Button
              onClick={() => {
                gotoStep(current - 1);
              }}
            >
              Previous
            </Button>
          )}
          {current < formList.length - 1 && (
            <Button
              onClick={() => {
                gotoStep(current + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === formList.length - 1 && (
            <SaveButton {...saveButtonProps} />
          )}
        </>
      }
    >
      <Steps {...stepsProps}>
        <Step title="Teacher Information" />
        <Step title="Skills/Bio" />
      </Steps>

      <Form
        {...formProps}
        layout="vertical"
        style={{ marginTop: 30 }}
        onFinish={onFinish}
      >
        {formList[current]}
      </Form>
    </Create>
  );
};
