import { IResourceComponentsProps } from '@pankod/refine-core';
import {
  Col,
  DatePicker,
  Edit,
  Form,
  Image,
  Input,
  Row,
  Select,
  useForm,
} from '@pankod/refine-antd';
import dayjs from 'dayjs';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IUser } from 'interfaces';
import { Step2 } from 'components/teacher/editprofile/othersteps';

export const TeacherEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult } = useForm<IUser>();

  const teacherInfo = queryResult?.data?.data;

  return (
    <Edit
      isLoading={queryResult?.isLoading}
      pageHeaderProps={{
        extra: null,
      }}
      saveButtonProps={saveButtonProps}
    >
      <Form {...formProps} layout="vertical">
        {queryResult?.isSuccess && (
          <>
            <Row gutter={16}>
              <Col span={24}>
                <Row justify="center">
                  <Image
                    width={130}
                    height={130}
                    style={{ borderRadius: '50%' }}
                    src={teacherInfo?.profilePic}
                    // fallback={"/tatyicon.png"}
                  />
                </Row>
              </Col>
              <Col span={12}>
                {' '}
                <Form.Item
                  label="Username"
                  name="userName"
                  rules={[
                    {
                      required: true,
                      message: 'Username is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      // type: 'email',
                      message: 'Email is required',
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Account Status"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: 'Cost is required',
                    },
                  ]}
                >
                  <Select
                    options={[
                      {
                        label: 'Pending',
                        value: 'Pending',
                      },
                      {
                        label: 'OnProfile',
                        value: 'OnProfile',
                      },
                      {
                        label: 'Waiting',
                        value: 'Waiting',
                      },
                      {
                        label: 'Approved',
                        value: 'Approved',
                      },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="DOB"
                  name="dob"
                  getValueProps={(value) => ({
                    value: value ? dayjs(value) : '',
                  })}
                  rules={[
                    {
                      required: true,
                      message: 'DOB is required',
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={(d) => !d || d.isAfter(new Date())}
                    format="YYYY-MM-DD"
                    // onChange={(_, dateString) => console.log(dateString)}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <hr style={{ width: '100%', borderTop: '1px dashed #12229d' }} />
              <Step2 profile={teacherInfo?.profileId} />
            </Row>
          </>
        )}
      </Form>
    </Edit>
  );
};
