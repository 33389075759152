import {
  IResourceComponentsProps,
  useNavigation,
  useShow,
} from "@pankod/refine-core";
import {
  Show,
  MarkdownField,
  Row,
  Col,
  Rate,
  Icons,
} from "@pankod/refine-antd";

import { ISession } from "interfaces";
import { SessionActions } from "components/SessonActions";
import { Enroled } from "components/session/enroled";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import timezones from "assets/timezones";

dayjs.extend(utc);
dayjs.extend(timezone);

export const SessionsShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ISession>();
  const { show } = useNavigation();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // console.log(record);

  const tmz = timezones.find((tz) => tz.id === record?.timeZone);

  const style = { color: "#000", paddingRight: 3 };

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.title || ""}
        pageHeaderProps={{
          extra: <div>{!isLoading && <SessionActions record={record!} />}</div>,
        }}
      >
        {!isLoading && (
          <div>
            <Row justify='center' gutter={16}>
              <Col span={12}>
                <video
                  poster={record?.thumbnail}
                  src={record?.videoPitch}
                  style={{
                    width: "100%",
                    height: "auto",
                    borderRadius: "20px",
                  }}
                  controls
                ></video>
              </Col>

              <Col span={8} style={{ margin: "auto" }} offset={4}>
                <b style={{ color: "#000", paddingRight: 11 }}>
                  Knowledgeable:{" "}
                </b>
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: "#FA8C16",
                  }}
                />
                <br />
                <b style={{ color: "#000", paddingRight: 66 }}>Delivery: </b>
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: "#FA8C16",
                  }}
                />
                <br />
                <b style={{ color: "#000", paddingRight: 86 }}>Value: </b>
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: "#FA8C16",
                  }}
                />
                <br />
                <b style={{ color: "#000", paddingRight: 13 }}>
                  Recommended:{" "}
                </b>{" "}
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: "#FA8C16",
                  }}
                />
              </Col>
            </Row>

            <hr />

            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <b style={style}>Instructor: </b>
                <span
                  onClick={() => show("teachers", record?.author?._id!)}
                  style={{ cursor: "pointer", color: "green" }}
                >
                  {record?.author?.userName}{" "}
                </span>{" "}
                <br />
                <b style={style}>Session Date: </b>
                {record?.recurringDetails ? (
                  <span>Recurring on {record?.recurringDetails?.day}s</span>
                ) : (
                  dayjs(record?.dateTime).tz(record?.timeZone).format("lll")
                )}
                <br />
                <b style={style}>Time Zone: </b>
                {tmz?.name} <br />
                <b style={style}>Category: </b>
                {record?.category.join(", ")} <br />
                {record?.resources !== "" && (
                  <div>
                    <b style={style}>Resources </b>
                    <span
                      style={{
                        textAlign: "left",
                        whiteSpace: "pre-line",
                      }}
                    >
                      <br />
                      {record?.resources}
                    </span>
                    <br />
                  </div>
                )}
              </Col>

              <Col span={8} style={{ margin: "auto" }} offset={4}>
                <b style={style}>Language of Instruction: </b>
                {record?.language} <br />
                <b style={style}>Capacity: </b>
                {record?.capacity} <br />
                <b style={style}>Cost: </b>
                {record?.cost} <br />
                {record?.handouts && (
                  <div>
                    <b style={style}>Handouts </b>
                    <span
                      style={{
                        textAlign: "left",
                        whiteSpace: "pre-line",
                      }}
                    >
                      <br />
                      {record?.handouts}
                    </span>
                    <br />
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ marginTop: 20 }}>
                <b style={style}>What Participants will learn</b> <br />
                <MarkdownField value={record?.description} />
              </Col>
            </Row>
          </div>
        )}
      </Show>
      {!isLoading && (
        <div style={{ marginTop: 30 }}>
          <Row>
            <Col span={24}>
              <Enroled lessonId={record?._id} />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
