import { useNavigation } from "@pankod/refine-core";
import {
  Typography,
  Timeline,
  useSimpleList,
  AntdList,
  Tooltip,
} from "@pankod/refine-antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

// import { any } from "interfaces";

dayjs.extend(relativeTime);

export const RecentLogins: React.FC = () => {
  const { show } = useNavigation();

  const { listProps } = useSimpleList<any>({
    resource: "logs",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    pagination: {
      pageSize: 3,
      simple: true,
      hideOnSinglePage: true,
    },
  });

  const { dataSource } = listProps;

  const { Text } = Typography;

  return (
    <AntdList {...listProps}>
      <Timeline>
        {dataSource?.map(({ _id, userName, userType, lastLogin }) => (
          <Timeline.Item
            className='timeline__point'
            key={_id}
            color={userType === "Teacher" ? "rgb(0, 191, 179)" : "#1f384b"}
          >
            <div
              style={{
                backgroundColor:
                  userType === "Teacher" ? "rgb(0, 191, 179)" : "#1f384b",
                color: "#fff",
                borderRadius: 10,
                padding: 10,
                display: "flex",
                flexDirection: "column",
                cursor: userType === "Teacher" ? "pointer" : "",
              }}
              onClick={() => userType === "Teacher" && show("teachers", _id)}
            >
              <Tooltip
                overlayInnerStyle={{ color: "#fff" }}
                color='green'
                placement='topLeft'
                title={userType}
              >
                <Text strong style={{ color: "#fff" }}>
                  {userName}
                </Text>
              </Tooltip>

              <Text italic style={{ color: "#fff" }}>
                {dayjs(lastLogin).fromNow()}
              </Text>
              <Text style={{ color: "#fff" }}>
                {userType === "Student" ? "Participant" : userType}
              </Text>
            </div>
          </Timeline.Item>
        ))}
      </Timeline>
    </AntdList>
  );
};
