import { IResourceComponentsProps, useShow } from '@pankod/refine-core';
import {
  Show,
  MarkdownField,
  Row,
  Col,
  DateField,
  UrlField,
  Rate,
  Icons,
} from '@pankod/refine-antd';

import { ISession } from 'interfaces';
import { SessionActions } from 'components/SessonActions';
// import { Enroled } from "components/session/enroled";

export const RevenueShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<ISession>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const style = { color: '#000', paddingRight: 3 };

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.title || ''}
        pageHeaderProps={{
          extra: <div>{!isLoading && <SessionActions record={record!} />}</div>,
        }}
      >
        {!isLoading && (
          <div>
            <Row justify="center">
              <Col span={12}>
                <video
                  poster={record?.thumbnail}
                  src={record?.videoPitch}
                  style={{ width: '70%', height: 'auto' }}
                  controls
                ></video>
              </Col>
              <Col span={12}>
                <br />
                <b style={{ color: '#000', paddingRight: 8 }}>Knowledge: </b>
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: '#FA8C16',
                  }}
                />
                <br />
                <br />
                <b style={{ color: '#000', paddingRight: 30 }}>Delivery: </b>
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: '#FA8C16',
                  }}
                />
                <br />
                <br />
                <b style={{ color: '#000', paddingRight: 50 }}>Value: </b>
                <Rate
                  character={<Icons.StarOutlined />}
                  disabled
                  value={5}
                  style={{
                    color: '#FA8C16',
                  }}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col span={12}>
                <b style={style}>Instructor: </b>
                {record?.author?.userName} <br />
                <b style={style}>Session Date: </b>
                <DateField value={record?.dateTime} format="LLL" />
                <br />
                <b style={style}>Time Zone: </b>
                {record?.timeZone} <br />
                <b style={style}>Category: </b>
                {record?.category.join(', ')} <br />
                {record?.resources !== '' && (
                  <div>
                    <b style={style}>Resources </b>
                    <span
                      style={{
                        textAlign: 'left',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <br />
                      {record?.resources}
                    </span>
                    <br />
                  </div>
                )}
              </Col>

              <Col span={12}>
                <b style={style}>Session Link: </b>
                <UrlField
                  target="_blank"
                  rel="noopener noreferrer"
                  value={`https://session.teachersandcoaches.com/${record?.lessonLink}`}
                  children={record?.lessonLink}
                />{' '}
                <br />
                <b style={style}>Language of Instruction: </b>
                {record?.language} <br />
                <b style={style}>Capacity: </b>
                {record?.capacity} <br />
                <b style={style}>Cost: </b>
                {record?.cost} <br />
                {record?.handouts && (
                  <div>
                    <b style={style}>Handouts </b>
                    <span
                      style={{
                        textAlign: 'left',
                        whiteSpace: 'pre-line',
                      }}
                    >
                      <br />
                      {record?.handouts}
                    </span>
                    <br />
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ marginTop: 20 }}>
                <b style={style}>What Participants will learn</b> <br />
                <MarkdownField value={record?.description} />
              </Col>
            </Row>
          </div>
        )}
      </Show>
      {/* <div style={{ marginTop: 30 }}>
        <Row>
          <Col span={24}>
            <Enroled />
          </Col>
        </Row>
      </div> */}
    </>
  );
};
