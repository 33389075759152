export function cutText(text: string, maxSize: number) {
  if (text.length < maxSize) return text;
  //trim the string to the maximum length
  let trimmedString = (text || "").substring(0, maxSize);

  //re-trim if we are in the middle of a word
  trimmedString =
    trimmedString.substring(
      0,
      Math.min(trimmedString.length, trimmedString.lastIndexOf(" "))
    ) + " ...";
  return trimmedString;
}
