import { useNavigation, useNotification, useUpdate } from "@pankod/refine-core";
import { Button, DeleteButton, EditButton } from "@pankod/refine-antd";
import { IUser } from "interfaces";
import axios from "axios";

type UserActionProps = {
  record: IUser;
};

const { REACT_APP_MEDIA_URL } = process.env;

export const UserActions: React.FC<UserActionProps> = ({ record }) => {
  const { open } = useNotification();
  const { mutate } = useUpdate<IUser>();
  const { goBack } = useNavigation();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <div
        hidden={record?.status === "Approved" || record?.status === "Suspended"}
      >
        <Button
          style={{ borderColor: "#67be23", color: "#67be23" }}
          onClick={() => {
            mutate(
              {
                id: String(record?._id),
                resource: "teachers",
                values: {
                  status: "Approved",
                  resume: false,
                },
                successNotification: false,
                mutationMode: "optimistic",
              },
              {
                onSuccess: () => {
                  open?.({
                    type: "success",
                    message: `${record?.userName}'s account has been approved, and has been notified`,
                    description: "Account aproval",
                    key: record?._id,
                  });
                },
              }
            );
          }}
        >
          Approve
        </Button>
      </div>
      <div hidden={record?.status === "Waiting"}>
        <Button
          style={{
            borderColor: record?.status === "Suspended" ? "#67be23" : "#FFA500",
            color: record?.status === "Suspended" ? "#67be23" : "#FFA500",
            marginLeft: 8,
          }}
          onClick={() => {
            mutate(
              {
                id: String(record?._id),
                resource: "teachers",
                values: {
                  status:
                    record?.status === "Suspended" ? "Approved" : "Suspended",
                  resume: true,
                },
                successNotification: false,
                mutationMode: "optimistic",
              },
              {
                onSuccess: () => {
                  open?.({
                    type: "success",
                    message: `${record?.userName}'s account has been ${
                      record?.status === "Suspended"
                        ? "unsuspended"
                        : "suspended"
                    }`,
                    description: "Account Suspension",
                    key: record?._id,
                  });
                },
              }
            );
          }}
        >
          {record?.status === "Suspended" ? "Unsuspend" : "Suspend"}
        </Button>
      </div>
      <div style={{ paddingLeft: 10 }}>
        <EditButton hideText size='small' recordItemId={record?._id} />
      </div>
      <div style={{ paddingLeft: 10 }}>
        <DeleteButton
          hideText
          size='small'
          recordItemId={record?._id}
          onSuccess={async (value) => {
            let fileToDel = value?.data?.split("/").pop();
            await axios
              .delete(
                `${REACT_APP_MEDIA_URL}/media/${fileToDel}?folderName=teachers`
              )
              .then(() => {
                goBack();
              });
          }}
        />
      </div>
    </div>
  );
};
