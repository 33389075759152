import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {
  Show,
  Typography,
  Tag,
  // MarkdownField,
  Row,
  Col,
  Rate,
  Image,
  EmailField,
  DateField,
  Icons,
} from "@pankod/refine-antd";

import { IUser } from "interfaces";
import { UserActions } from "components/UserActions";

const { Title } = Typography;

export const TeacherShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IUser>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const style = { color: "#000", paddingRight: 3 };

  return (
    <Show
      isLoading={isLoading}
      title={record?.userName || ""}
      pageHeaderProps={{
        extra: <div>{!isLoading && <UserActions record={record!} />}</div>,
      }}
    >
      {!isLoading && (
        <>
          <Row
            justify='center'
            style={{ alignItems: "center", marginBottom: 10 }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Image
                width={130}
                height={130}
                style={{ borderRadius: "50%" }}
                src={record?.profilePic}
                // fallback={<UserOutlined />}
              />
              <Rate
                character={<Icons.StarOutlined />}
                disabled
                value={5}
                style={{
                  color: "#FA8C16",
                }}
              />
            </div>
            <div style={{ paddingLeft: 25 }}>
              <Title level={5}>{record?.userName}</Title>
              {record?.profileId?.title} <br />
              <Tag
                color={
                  record?.status === "Approved"
                    ? "success"
                    : record?.status === "OnProfile"
                    ? "purple"
                    : record?.status === "Waiting"
                    ? "orange"
                    : record?.status === "Suspended"
                    ? "red"
                    : "processing"
                }
              >
                {record?.status}
              </Tag>
            </div>
          </Row>
          <Row>
            <Col span={12}>
              <b style={style}>Email:</b> <EmailField value={record?.email} />{" "}
              <br />
              <b style={style}>DOB:</b>{" "}
              <DateField value={record?.profileId?.dob} format={"LL"} /> <br />
              <b style={style}>Joined On:</b>{" "}
              <DateField value={record?.createdAt} format={"LL"} /> <br />
            </Col>
            <Col span={12}>
              <b style={style}>Areas Of Specialty: </b>{" "}
              {record?.profileId?.subjects?.join(", ")}
              <br />
              <b style={style}>Settlements Account:</b>{" "}
              <EmailField value={record?.profileId?.settlements} /> <br />
            </Col>
          </Row>

          <Row>
            <Col span={24} style={{ marginTop: 20 }}>
              {/* <MarkdownField value={record?.profileId?.bio} /> */}

              {record && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: record?.profileId?.bio,
                  }}
                />
              )}
            </Col>
          </Row>
        </>
      )}
    </Show>
  );
};
