import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  useTable,
  getDefaultSortOrder,
  DateField,
  Space,
  // EditButton,
  DeleteButton,
  TagField,
  ShowButton,
  EmailField,
  Avatar,
  Typography,
  EditButton,
} from "@pankod/refine-antd";
import { IUser } from "interfaces";
import { UserOutlined } from "@ant-design/icons";
import axios from "axios";

export const TeacherList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IUser>({
    initialPageSize: 10,
    initialSorter: [
      {
        field: "_id",
        order: "desc",
      },
    ],
    syncWithLocation: false,
  });

  return (
    <List>
      <Table {...tableProps} rowKey='_id'>
        {/* <Table.Column
          dataIndex="id"
          key="id"
          title="ID"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("id", sorter)}
          sorter
        /> */}
        <Table.Column
          // align="center"
          key='profilePic'
          // dataIndex={["profilePic"]}
          title={"Name"}
          width='10%'
          render={(value) => (
            <Space>
              <Avatar
                size={70}
                src={value?.profilePic}
                icon={<UserOutlined />}
              />
              <Typography style={{ paddingLeft: 5 }}>
                {value?.userName}
              </Typography>
            </Space>
          )}
        />

        <Table.Column
          dataIndex='email'
          key='email'
          width='10%'
          title='Email'
          render={(value) => <EmailField value={value} />}
          defaultSortOrder={getDefaultSortOrder("email", sorter)}
          sorter
        />
        <Table.Column
          dataIndex='status'
          key='status'
          width='2%'
          title='Status'
          render={(value) => (
            <TagField
              value={value}
              color={
                value === "Approved"
                  ? "success"
                  : value === "OnProfile"
                  ? "purple"
                  : value === "Waiting"
                  ? "orange"
                  : value === "Suspended"
                  ? "red"
                  : "processing"
              }
            />
          )}
          defaultSortOrder={getDefaultSortOrder("status", sorter)}
          sorter
        />
        <Table.Column
          width='19%'
          dataIndex='lastLogin'
          key='lastLogin'
          title='Last Login'
          render={(value) =>
            value ? (
              <DateField value={value} style={{ fontSize: 13 }} format='lll' />
            ) : (
              <span>Never logged in</span>
            )
          }
          defaultSortOrder={getDefaultSortOrder("lastLogin", sorter)}
          sorter
        />
        <Table.Column
          dataIndex='createdAt'
          key='createdAt'
          title='Joined On'
          render={(value) => (
            <DateField style={{ fontSize: 13 }} value={value} format='lll' />
          )}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        {/* <Table.Column
          dataIndex={["category", "id"]}
          title="Category"
          render={(value) => {
            if (isLoading) {
              return <TextField value="Loading..." />;
            }

            return (
              <TextField
                value={
                  categoriesData?.data.find((item) => item.id === value)?.title
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder="Select Category"
                {...categorySelectProps}
              />
            </FilterDropdown>
          )}
        /> */}
        <Table.Column<IUser>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              {record?.profileId && (
                <>
                  <EditButton hideText size='small' recordItemId={record._id} />
                  <ShowButton hideText size='small' recordItemId={record._id} />
                </>
              )}

              <DeleteButton
                hideText
                size='small'
                recordItemId={record._id}
                onSuccess={async (value) => {
                  let fileToDel = value?.data?.split("/").pop();
                  await axios.delete(
                    `${process.env.REACT_APP_MEDIA_URL}/media/${fileToDel}?folderName=teachers`
                  );
                }}
              />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
