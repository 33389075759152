import {
  IResourceComponentsProps,
  useNavigation,
  useShow,
} from "@pankod/refine-core";
import {
  Col,
  DateField,
  DeleteButton,
  EmailField,
  MarkdownField,
  Row,
  Show,
} from "@pankod/refine-antd";

import { IContact } from "interfaces";

export const ContactShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IContact>();
  const { goBack } = useNavigation();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <>
      <Show
        isLoading={isLoading}
        title={record?.name || ""}
        pageHeaderProps={{
          extra: <DeleteButton onSuccess={() => goBack()} />,
        }}
      >
        {!isLoading && (
          <>
            <Row gutter={16}>
              <Col span={8}>
                <b>Name:</b> {record?.name}
              </Col>
              <Col span={8}>
                <b>Email:</b> <EmailField value={record?.email} />
              </Col>
              <Col span={8}>
                <b>Sent on:</b>{" "}
                <DateField value={record?.createdAt} format='LLL' />
              </Col>

              <Col span={24} style={{ marginTop: 20 }}>
                <MarkdownField value={record?.message} />
              </Col>
            </Row>
          </>
        )}
      </Show>
    </>
  );
};
