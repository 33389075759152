import {
  Space,
  useTable,
  List,
  Table,
  EmailField,
  DateField,
  DeleteButton,
  BooleanField,
  Row,
} from '@pankod/refine-antd';
import { ISession } from 'interfaces';

type enroledProps = {
  lessonId: any;
};

export const Enroled: React.FC<enroledProps> = ({ lessonId }) => {
  //   const { data, isLoading } = useTable<any>({
  //     resource: `allquotes/individual`,
  //     id: String(agentEmail),
  //   });
  //   const record = data?.data;

  const { tableProps } = useTable<any>({
    resource: 'enrolments',
    initialFilter: [
      {
        field: 'lessonId',
        operator: 'eq',
        value: lessonId,
      },
    ],
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  });

  return (
    <List
      pageHeaderProps={{
        title: 'Enroled Participants',
        subTitle: null,
        extra: '',
      }}
    >
      {!tableProps?.loading && !tableProps?.dataSource?.length ? (
        <Row justify="center">No enrolments</Row>
      ) : (
        <Table {...tableProps} rowKey="_id">
          <Table.Column
            key="userName"
            title="Username"
            render={(value) => {
              return value?.student?.userName;
            }}
          />
          <Table.Column
            key="email"
            title="email"
            render={(value) => {
              return <EmailField value={value?.student?.email} />;
            }}
          />
          <Table.Column
            key="createdAt"
            dataIndex="createdAt"
            title="Enrolled on"
            render={(value) => {
              return <DateField value={value} format="LLL" />;
            }}
          />
          <Table.Column
            key="calendared"
            dataIndex="calendared"
            title="Added To Calender"
            align="center"
            render={(value) => {
              return (
                <div style={{ color: value ? 'green' : 'red' }}>
                  <BooleanField
                    value={value}
                    valueLabelTrue="Added to calender"
                    valueLabelFalse="Not added to calender"
                  />
                </div>
              );
            }}
          />

          <Table.Column<ISession>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <DeleteButton
                  hideText
                  size="small"
                  resourceName="enrolments"
                  recordItemId={record._id}
                />{' '}
              </Space>
            )}
          />
        </Table>
      )}
    </List>
  );
};
