import { IResourceComponentsProps, useExport } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  ShowButton,
  Image,
  NumberField,
  ExportButton,
} from "@pankod/refine-antd";
import { IRevenue } from "interfaces";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import timezones from "assets/timezones";

dayjs.extend(utc);
dayjs.extend(timezone);

export const RevenueList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IRevenue>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  const { isLoading, triggerExport } = useExport<any>({
    sorter,
    // filters,
    pageSize: 50,
    maxItemCount: 50,
    mapData: (item) => {
      const tz = timezones.find((tz) => tz.name === item.timeZone);

      return {
        Title: item.title,
        Instructor: item.author,
        Enroled: item.enroled,
        SessionDate: dayjs(item.dateTime).tz(tz?.id).format("lll"),
        timeZone: item.timeZone,
        Cost: `$ ${item.cost.toFixed(2)}`,
        TotalEarned: item.cost * item.enroled,
        TeacherRevenue: item.cost * item.enroled * 0.8,
        Comission: item.cost * item.enroled * 0.2,
      };
    },
  });

  const Actions: React.FC = () => (
    <ExportButton onClick={triggerExport} loading={isLoading} />
  );

  return (
    <List
      title='Revenue'
      pageHeaderProps={{
        extra: <Actions />,
      }}
    >
      <Table {...tableProps} rowKey='_id'>
        <Table.Column
          key='thumbnail'
          render={(value) => (
            <Image width={64} height={36} src={value?.thumbnail} />
          )}
        />
        <Table.Column
          title='Title'
          key='title'
          render={(value) => {
            const tz = timezones.find((tz) => tz.name === value.timeZone);
            return (
              <>
                <TextField value={value.title} style={{ fontWeight: "bold" }} />{" "}
                <br />
                {dayjs(value.dateTime).tz(tz?.id).format("ll")}{" "}
                {dayjs(value.dateTime).tz(tz?.id).format("LT")} <br />
                <TextField value={value.timeZone} style={{ fontSize: 12 }} />
              </>
            );
          }}
        />
        <Table.Column
          dataIndex='author'
          key='author'
          title='Instructor'
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex='enroled'
          key='author'
          title='Enroled'
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex='cost'
          key='cost'
          title='Cost'
          render={(value) => (
            <NumberField
              options={{
                currency: "USD",
                style: "currency",
              }}
              value={value}
            />
          )}
        />

        <Table.Column
          key='Total'
          title='Total Earned'
          render={(value) => (
            <NumberField
              options={{
                currency: "USD",
                style: "currency",
              }}
              value={value.cost * value.enroled}
            />
          )}
        />

        <Table.Column
          key='teacherRevenue'
          title='Teacher Revenue'
          render={(value) => (
            <NumberField
              options={{
                currency: "USD",
                style: "currency",
              }}
              value={value.cost * value.enroled * 0.8}
            />
          )}
        />

        <Table.Column<any>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record.id} /> */}
              <ShowButton
                hideText
                size='small'
                resourceName='sessions'
                recordItemId={record._id}
              />
              {/* <DeleteButton hideText size="small" recordItemId={record.id} /> */}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
