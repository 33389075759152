import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Space,
  DateField,
  EmailField,
  Image,
  TextField,
  ShowButton,
  DeleteButton,
  getDefaultSortOrder,
} from "@pankod/refine-antd";
import { ISession } from "interfaces";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import timezones from "assets/timezones";
import { cutText } from "assets/helpers";

dayjs.extend(utc);
dayjs.extend(timezone);

export const ParticipantList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ISession>({});

  return (
    <List
      pageHeaderProps={{
        subTitle: null,
        extra: "",
      }}
    >
      <Table
        {...tableProps}
        expandable={{
          expandedRowRender: expandedRowRender,
        }}
        rowKey='_id'
      >
        <Table.Column
          key='userName'
          dataIndex='userName'
          title='Username'
          render={(value) => {
            return value;
          }}
        />
        <Table.Column
          key='email'
          dataIndex='email'
          title='email'
          render={(value) => {
            return <EmailField value={value} />;
          }}
        />
        <Table.Column
          key='lastLogin'
          dataIndex='lastLogin'
          title='Last Login'
          render={(value) => {
            return <DateField value={value} format='lll' />;
          }}
        />
        <Table.Column<ISession>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              <DeleteButton
                hideText
                size='small'
                // resourceName="enrolments"
                recordItemId={record._id}
              />{" "}
              {/*TODO: Make this delete the enrolled session, not the session it self*/}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

const EnrolmentsTable: React.FC<{ record: ISession }> = ({ record }) => {
  const { tableProps, sorter } = useTable<ISession>({
    resource: "sessions",
    permanentFilter: [
      {
        field: "student_id",
        operator: "eq",
        value: record._id,
      },
    ],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialPageSize: 5,
    syncWithLocation: false,
  });

  const totalEnroled = tableProps.pagination
    ? tableProps.pagination?.total || ""
    : "";

  return (
    <List
      title={
        !tableProps.loading
          ? `Enrolled for ${totalEnroled} session${totalEnroled > 1 ? "s" : ""}`
          : ""
      }
      pageHeaderProps={{
        subTitle: null,
        extra: "",
      }}
      createButtonProps={undefined}
    >
      <Table {...tableProps} rowKey='_id'>
        <Table.Column
          key='thumbnail'
          render={(value) => (
            <Image width={64} height={36} src={value?.thumbnail} />
          )}
        />
        <Table.Column
          dataIndex='title'
          key='title'
          title='Title'
          render={(value) => <TextField value={cutText(value, 20)} />}
        />
        <Table.Column
          key='author'
          title='Instructor'
          render={(value) => <TextField value={value?.author?.userName} />}
        />
        <Table.Column
          key='dateTime'
          title='Session Date'
          render={(value) => {
            return dayjs(value.dateTime).tz(value.timeZone).format("lll");
          }}
          defaultSortOrder={getDefaultSortOrder("value.dateTime", sorter)}
          sorter
        />
        <Table.Column
          key='timeZone'
          title='TimeZone'
          // render={(value) => <TextField value={cutText(value, 20)} />}
          render={(value) => {
            const tz = timezones.find((tz) => tz.id === value.timeZone);
            return <TextField value={cutText(tz?.name!, 20)} />;
          }}
        />

        <Table.Column<ISession>
          title='Actions'
          dataIndex='actions'
          render={(_, session) => (
            <Space>
              <ShowButton
                hideText
                size='small'
                resourceName='sessions'
                recordItemId={session._id}
              />
              <DeleteButton
                hideText
                size='small'
                resourceName='enrolments'
                recordItemId={record._id}
              />{" "}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

const expandedRowRender = (record: ISession) => {
  return <EnrolmentsTable record={record} />;
};
