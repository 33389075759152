import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  getDefaultSortOrder,
  DateField,
  EmailField,
  NumberField,
  UrlField,
} from "@pankod/refine-antd";
import { IPayment } from "interfaces";

export const PaymentsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<IPayment>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  console.log(tableProps?.dataSource);

  return (
    <List
      pageHeaderProps={{
        subTitle: null,
        extra: "",
      }}
    >
      <Table {...tableProps} rowKey='_id'>
        <Table.Column
          key='payer'
          title='Given Name'
          render={(value) => (
            <TextField
              value={
                value?.payer?.name?.given_name +
                " " +
                value?.payer?.name?.surname
              }
            />
          )}
        />
        <Table.Column
          key='student_email'
          title='Student Email'
          render={(value) => <EmailField value={value?.payer?.email_address} />}
        />
        <Table.Column
          dataIndex='purchase_units'
          key='purchase_units'
          title='Amount'
          render={(value) => (
            <NumberField
              options={{
                currency: "USD",
                style: "currency",
                // notation: "compact",
              }}
              value={value[0]?.amount?.value}
            />
          )}
        />
        <Table.Column
          key='description'
          title='Description'
          render={(value) => (
            <UrlField
              value={`/sessions/show/${value?.lessonId}`}
              children={value?.purchase_units[0]?.description || ""}
            />
          )}
        />
        <Table.Column
          dataIndex='createdAt'
          key='createdAt'
          title='Payed On'
          render={(value) => <DateField value={value} format='LLL' />}
          defaultSortOrder={getDefaultSortOrder("createdAt", sorter)}
          sorter
        />
        {/* <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record.id} />
              <ShowButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size="small" recordItemId={record.id} />
            </Space>
          )}
        /> */}
      </Table>
    </List>
  );
};
