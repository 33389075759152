import { useNavigation } from "@pankod/refine-core";
import { useGetIdentity } from "@pankod/refine-core";

import {
  AntdLayout,
  Icons,
  Input,
  Avatar,
  Space,
  Grid,
  Row,
  Col,
  AutoComplete,
} from "@pankod/refine-antd";
import {
  UserOutlined,
  CustomerServiceOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
import "./style.less";

const { SearchOutlined } = Icons;
const { useBreakpoint } = Grid;

type headerProps = {
  color?: string;
};

export const Header: React.FC<headerProps> = ({ color }) => {
  const { push } = useNavigation();

  const { data: user } = useGetIdentity();
  const screens = useBreakpoint();

  return (
    <AntdLayout.Header
      style={{
        padding: "0px 24px",
        height: "64px",
        backgroundColor: color || "#FFF",
      }}
    >
      <Row align='middle' justify={screens.sm ? "space-between" : "end"}>
        <Col xs={0} sm={12}>
          <AutoComplete
            dropdownClassName='header-search'
            style={{ width: "100%", maxWidth: "550px" }}
            // options={options}
            filterOption={false}
            // onSearch={debounce(
            //     (value: string) => setValue(value),
            //     300,
            // )}
          >
            <Input
              size='large'
              placeholder={"Search Term here"}
              suffix={<SearchOutlined />}
            />
          </AutoComplete>
        </Col>
        {color && (
          <b
            style={{ color: color ? "#FFF" : color, cursor: "pointer" }}
            onClick={(): void => push("/")}
          >
            <DashboardOutlined /> Back to Dashboard
          </b>
        )}
        {!color && (
          <Col
            style={{
              cursor: "pointer",
            }}
            onClick={(): void => push("support")}
          >
            <CustomerServiceOutlined
              style={{
                fontSize: 20,
                marginRight: 10,
                cursor: "pointer",
                color: color ? "#FFF" : color,
              }}
            />
            Support
          </Col>
        )}
        <Col>
          <Space size='middle'>
            <Space>
              <div
                style={{
                  display: screens.lg ? "block" : "none",
                  color: color ? "#FFF" : color,
                }}
              >
                {user?.name}
              </div>
            </Space>
            <Avatar
              size='small'
              icon={<UserOutlined />}
              src={user?.avatar}
              alt={""}
            />
          </Space>
        </Col>
      </Row>
    </AntdLayout.Header>
  );
};
