const timezones = [
  {
    name: "(UTC-12:00) International Date Line West",
    id: "Etc/GMT+12",
  },
  {
    name: "(UTC-11:00) Coordinated Universal Time-11",
    id: "Etc/GMT+11",
  },
  {
    name: "(UTC-10:00) Hawaii",
    id: "Pacific/Honolulu",
  },
  {
    name: "(UTC-09:00) Alaska",
    id: "America/Anchorage",
  },
  {
    name: "(UTC-08:00) Baja California",
    id: "America/Santa_Isabel",
  },
  {
    name: "(UTC-08:00) Pacific Time (US and Canada)",
    id: "America/Los_Angeles",
  },
  {
    name: "(UTC-07:00) Chihuahua, La Paz, Mazatlan",
    id: "America/Chihuahua",
  },
  {
    name: "(UTC-07:00) Arizona",
    id: "America/Phoenix",
  },
  {
    name: "(UTC-07:00) Mountain Time (US and Canada)",
    id: "America/Denver",
  },
  {
    name: "(UTC-06:00) Central America",
    id: "America/Guatemala",
  },
  {
    name: "(UTC-06:00) Central Time (US and Canada)",
    id: "America/Chicago",
  },
  {
    name: "(UTC-06:00) Saskatchewan",
    id: "America/Regina",
  },
  {
    name: "(UTC-06:00) Guadalajara, Mexico City, Monterey",
    id: "America/Mexico_City",
  },
  {
    name: "(UTC-05:00) Bogota, Lima, Quito",
    id: "America/Bogota",
  },
  {
    name: "(UTC-05:00) Indiana (East)",
    id: "America/Indiana/Indianapolis",
  },
  {
    name: "(UTC-05:00) Eastern Time (US and Canada)",
    id: "America/New_York",
  },
  {
    name: "(UTC-04:30) Caracas",
    id: "America/Caracas",
  },
  {
    name: "(UTC-04:00) Atlantic Time (Canada)",
    id: "America/Halifax",
  },
  {
    name: "(UTC-04:00) Asuncion",
    id: "America/Asuncion",
  },
  {
    name: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan",
    id: "America/La_Paz",
  },
  {
    name: "(UTC-04:00) Cuiaba",
    id: "America/Cuiaba",
  },
  {
    name: "(UTC-04:00) Santiago",
    id: "America/Santiago",
  },
  {
    name: "(UTC-03:00) Brasilia",
    id: "America/Sao_Paulo",
  },
  {
    name: "(UTC-03:00) Greenland",
    id: "America/Godthab",
  },
  {
    name: "(UTC-03:00) Cayenne, Fortaleza",
    id: "America/Cayenne",
  },
  {
    name: "(UTC-03:00) Buenos Aires",
    id: "America/Argentina/Buenos_Aires",
  },
  {
    name: "(UTC-03:00) Montevideo",
    id: "America/Montevideo",
  },
  {
    name: "(UTC-02:00) Coordinated Universal Time-2",
    id: "Etc/GMT+2",
  },
  {
    name: "(UTC-01:00) Cape Verde",
    id: "Atlantic/Cape_Verde",
  },
  {
    name: "(UTC-01:00) Azores",
    id: "Atlantic/Azores",
  },
  {
    name: "(UTC+00:00) Casablanca",
    id: "Africa/Casablanca",
  },
  {
    name: "(UTC+00:00) Monrovia, Reykjavik",
    id: "Atlantic/Reykjavik",
  },
  {
    name: "(UTC+00:00) Dublin, Edinburgh, Lisbon, London",
    id: "Europe/London",
  },
  {
    name: "(UTC+00:00) Coordinated Universal Time",
    id: "Etc/GMT",
  },
  {
    name: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    id: "Europe/Berlin",
  },
  {
    name: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris",
    id: "Europe/Paris",
  },
  {
    name: "(UTC+01:00) West Central Africa",
    id: "Africa/Lagos",
  },
  {
    name: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague	",
    id: "Europe/Budapest",
  },
  {
    name: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    id: "Europe/Warsaw",
  },
  {
    name: "(UTC+01:00) Windhoek",
    id: "Africa/Windhoek",
  },
  {
    name: "(UTC+02:00) Athens, Bucharest, Istanbul",
    id: "Europe/Istanbul",
  },
  {
    name: "(UTC+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    id: "Europe/Kiev",
  },
  {
    name: "(UTC+02:00) Cairo",
    id: "Africa/Cairo",
  },
  {
    name: "(UTC+02:00) Damascus",
    id: "Asia/Damascus",
  },
  {
    name: "(UTC+02:00) Amman",
    id: "Asia/Amman",
  },
  {
    name: "(UTC+02:00) Harare, Pretoria",
    id: "Africa/Johannesburg",
  },
  {
    name: "(UTC+02:00) Jerusalem",
    id: "Asia/Jerusalem",
  },
  {
    name: "(UTC+02:00) Beirut",
    id: "Asia/Beirut",
  },
  {
    name: "(UTC+03:00) Baghdad",
    id: "Asia/Baghdad",
  },
  {
    name: "(UTC+03:00) Minsk",
    id: "Europe/Minsk",
  },
  {
    name: "(UTC+03:00) Kuwait, Riyadh",
    id: "Asia/Riyadh",
  },
  {
    name: "(UTC+03:00) Nairobi",
    id: "Africa/Nairobi",
  },
  {
    name: "(UTC+03:30) Tehran",
    id: "Asia/Tehran",
  },
  {
    name: "(UTC+04:00) Moscow, St. Petersburg, Volgograd",
    id: "Europe/Moscow",
  },
  {
    name: "(UTC+04:00) Tbilisi",
    id: "Asia/Tbilisi",
  },
  {
    name: "(UTC+04:00) Yerevan",
    id: "Asia/Yerevan",
  },
  {
    name: "(UTC+04:00) Abu Dhabi, Muscat",
    id: "Asia/Dubai",
  },
  {
    name: "(UTC+04:00) Baku",
    id: "Asia/Baku",
  },
  {
    name: "(UTC+04:00) Port Louis",
    id: "Indian/Mauritius",
  },
  {
    name: "(UTC+04:30) Kabul",
    id: "Asia/Kabul",
  },
  {
    name: "(UTC+05:00) Tashkent",
    id: "Asia/Tashkent",
  },
  {
    name: "(UTC+05:00) Islamabad, Karachi",
    id: "Asia/Karachi",
  },
  {
    name: "(UTC+05:30) Sri Jayewardenepura Kotte",
    id: "Asia/Colombo",
  },
  {
    name: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    id: "Asia/Kolkata",
  },
  {
    name: "(UTC+05:45) Kathmandu",
    id: "Asia/Kathmandu",
  },
  {
    name: "(UTC+06:00) Astana",
    id: "Asia/Almaty",
  },
  {
    name: "(UTC+06:00) Dhaka",
    id: "Asia/Dhaka",
  },
  {
    name: "(UTC+06:00) Yekaterinburg",
    id: "Asia/Yekaterinburg",
  },
  {
    name: "(UTC+06:30) Yangon",
    id: "Asia/Yangon",
  },
  {
    name: "(UTC+07:00) Bangkok, Hanoi, Jakarta",
    id: "Asia/Bangkok",
  },
  {
    name: "(UTC+07:00) Novosibirsk",
    id: "Asia/Novosibirsk",
  },
  {
    name: "(UTC+08:00) Krasnoyarsk",
    id: "Asia/Krasnoyarsk",
  },
  {
    name: "(UTC+08:00) Ulaanbaatar",
    id: "Asia/Ulaanbaatar",
  },
  {
    name: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi",
    id: "Asia/Shanghai",
  },
  {
    name: "(UTC+08:00) Perth",
    id: "Australia/Perth",
  },
  {
    name: "(UTC+08:00) Kuala Lumpur, Singapore",
    id: "Asia/Singapore",
  },
  {
    name: "(UTC+08:00) Taipei",
    id: "Asia/Taipei",
  },
  {
    name: "(UTC+09:00) Irkutsk",
    id: "Asia/Irkutsk",
  },
  {
    name: "(UTC+09:00) Seoul",
    id: "Asia/Seoul",
  },
  {
    name: "(UTC+09:00) Osaka, Sapporo, Tokyo",
    id: "Asia/Tokyo",
  },
  {
    name: "(UTC+09:30) Darwin",
    id: "Australia/Darwin",
  },
  {
    name: "(UTC+09:30) Adelaide",
    id: "Australia/Adelaide",
  },
  {
    name: "(UTC+10:00) Hobart",
    id: "Australia/Hobart",
  },
  {
    name: "(UTC+10:00) Yakutsk",
    id: "Asia/Yakutsk",
  },
  {
    name: "(UTC+10:00) Brisbane",
    id: "Australia/Brisbane",
  },
  {
    name: "(UTC+10:00) Guam, Port Moresby",
    id: "Pacific/Port_Moresby",
  },
  {
    name: "(UTC+10:00) Canberra, Melbourne, Sydney",
    id: "Australia/Sydney",
  },
  {
    name: "(UTC+11:00) Vladivostok",
    id: "Asia/Vladivostok",
  },
  {
    name: "(UTC+11:00) Solomon Islands, New Caledonia",
    id: "Pacific/Guadalcanal",
  },
  {
    name: "(UTC+12:00) Coordinated Universal Time+12",
    id: "Etc/GMT-12",
  },
  {
    name: "(UTC+12:00) Fiji, Marshall Islands",
    id: "Pacific/Fiji",
  },
  {
    name: "(UTC+12:00) Magadan",
    id: "Asia/Magadan",
  },
  {
    name: "(UTC+12:00) Auckland, Wellington",
    id: "Pacific/Auckland",
  },
  {
    name: "(UTC+13:00) Nuku'alofa",
    id: "Pacific/Tongatapu",
  },
  {
    name: "(UTC+13:00) Samoa",
    id: "Pacific/Apia",
  },
];

export default timezones;
