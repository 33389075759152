import {
  Typography,
  useTable,
  Table,
  Avatar,
  Space,
  Tag,
  ShowButton,
} from "@pankod/refine-antd";

import "./style.less";

// import { OrderActions } from "components";

// import { any } from "interfaces";

const { Text, Paragraph } = Typography;

export const NewTeachers: React.FC = () => {
  const { tableProps } = useTable<any>({
    resource: "teachers",
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialPageSize: 1,
    permanentFilter: [
      {
        field: "newBees",
        operator: "eq",
        value: "latest5",
      },
    ],
    syncWithLocation: false,
  });

  return (
    <Table
      {...tableProps}
      pagination={{ ...tableProps.pagination, simple: true }}
      showHeader={false}
      rowKey='_id'
    >
      <Table.Column<any>
        dataIndex='profilePic'
        key='profilePic'
        render={(value) => (
          <Avatar
            size={{
              md: 60,
              lg: 108,
              xl: 132,
              xxl: 144,
            }}
            src={value}
          />
        )}
      />
      <Table.Column<any>
        // dataIndex="usern"
        className='recent-orders-col'
        key='username'
        render={(_, record) => (
          <div className='recent-orders-col__title'>
            <Text className='recent-orders-col__title--up' strong>
              {record.userName}
            </Text>
            <Paragraph
              ellipsis={{
                rows: 2,
                tooltip: record?.profileId?.subjects?.join(", "),
                symbol: <span>...</span>,
              }}
            >
              {/* {record?.profileId?.bio} */}
              {record && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: record?.profileId?.bio,
                  }}
                />
              )}
            </Paragraph>

            <Text strong className='orderId'>
              {record?.profileId?.title}
            </Text>
          </div>
        )}
      />
      <Table.Column<any>
        dataIndex='amount'
        render={(value, record) => (
          <Space
            size='large'
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tag
              color={
                record?.status === "Approved"
                  ? "success"
                  : record?.status === "OnProfile"
                  ? "purple"
                  : record?.status === "Waiting"
                  ? "orange"
                  : record?.status === "Suspended"
                  ? "red"
                  : "processing"
              }
            >
              {record?.status}
            </Tag>
          </Space>
        )}
      />
      <Table.Column<any>
        fixed='right'
        key='actions'
        align='center'
        render={(_, record) => (
          <Space>
            <ShowButton
              hideText
              size='small'
              resourceName='teachers'
              recordItemId={record._id}
            />
          </Space>
        )}
      />
    </Table>
  );
};
