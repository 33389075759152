import { Form, Input, Col, Checkbox } from '@pankod/refine-antd';
import { categories } from 'assets/skills';

// const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

type profileProps = {
  profile: any;
};

// const { REACT_APP_BASE_URL } = process.env;

export const Step2: React.FC<profileProps> = ({ profile }) => {
  return (
    <>
      <Col span={12}>
        <Form.Item
          initialValue={profile?.title}
          label="Display Title"
          name="title"
          rules={[
            {
              required: true,
              message: 'Settlements is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          initialValue={profile?.settlements}
          label="Settlements adress"
          name="settlements"
          rules={[
            {
              required: true,
              message: 'Settlements is required',
            },
          ]}
        >
          <Input type="email" />
        </Form.Item>
      </Col>

      <Col span={24}>
        <Form.Item
          label="What categories of skills does the teacher want to share?"
          name="subjects"
          initialValue={profile?.subjects}
          rules={[
            {
              required: true,
              message: 'Categories is required',
            },
          ]}
        >
          <CheckboxGroup
            name="subjects"
            options={categories}
            // value={profile?.subjects}
            // onChange={onChange}
          />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          initialValue={profile?.bio}
          label="Teachers's bio"
          name="bio"
          rules={[
            {
              max: 500,
              required: true,
              message: 'Bio is required',
            },
            // ({ getFieldValue }) => ({
            //   validator(_, value) {
            //     if (!value || getFieldValue('bio').length >= 140) {
            //       return Promise.resolve();
            //     }
            //     return Promise.reject(
            //       <span style={{ color: 'red' }}>
            //         Bio must be at least 140 characters
            //       </span>
            //     );
            //   },
            // }),
          ]}
        >
          <TextArea rows={5} maxLength={500} />
        </Form.Item>
      </Col>

      {/* <Row justify="center">
          <Form.Item
            initialValue={profile?.logo}
            label="Agency Logo (Max 500x500px)"
            name="agency_logo"
            rules={[
              {
                required: true,
                message: 'Logo is required',
              },
            ]}
          >
            <Upload
              defaultFileList={initialImage}
              name="agencyLogo"
              accept="image/*"
              action={`//${REACT_APP_BASE_URL?.slice(7)?.replace(
                '/admin',
                '/agents'
              )}/profiles/uploadAgencyLogo`}
              listType="picture"
              maxCount={1}
              multiple={false}
              onChange={(info: any) => {
                if (info.file.status === 'done') {
                  message.success('Logo uploaded successfully.');
                }
                if (info.file.status === 'removed') {
                  axios.delete(
                    `${REACT_APP_BASE_URL?.replace(
                      '/admin',
                      '/agents'
                    )}/profiles/logo/remove/${
                      info.file.response
                        ? info.file.response.split('/')
                        : profile?.logo
                    }`
                  );
                } else if (info.file.status === 'error') {
                  message.error(`${info.file.name} file upload failed.`);
                }
              }}
            >
              <b
                style={{
                  width: '100%',
                  backgroundColor: 'whitesmoke',
                  cursor: 'pointer',
                  borderRadius: 15,
                  padding: 10,
                }}
              >
                Upload agency Logo
              </b>
            </Upload>
          </Form.Item>
        </Row> */}
    </>
  );
};
// https://codesandbox.io/s/heuristic-roentgen-mu0ot?file=/src/GroupForm.js:1663-1688
