import { Card, Col, Row, Typography } from '@pankod/refine-antd';
import { useCustom, useApiUrl, useNavigation } from '@pankod/refine-core';

import '../style.css';

export const Totals: React.FC = () => {
  const apiUrl = useApiUrl();

  const { data } = useCustom({
    url: `${apiUrl}/logs`,
    method: 'get',
    config: {
      query: {
        count: 'totals',
      },
    },
  });

  const { list } = useNavigation();

  const { Text } = Typography;

  return (
    <Col md={24}>
      <Row gutter={[16, 16]}>
        <Col xl={8} lg={24} md={24} sm={24} xs={24}>
          <Card
            bodyStyle={{
              padding: 10,
              paddingBottom: 0,
            }}
            onClick={() => list('teachers')}
            style={{
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              background: 'url(dashpics/teachers.png)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              height: 170,
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <div style={{ marginTop: 100 }}>
              {!data ? (
                <div style={{ marginLeft: '50%' }}>
                  <div className="dot-pulse"></div>
                </div>
              ) : (
                <Text strong italic style={{ color: '#fff', fontSize: 25 }}>
                  {data?.data?.teachers} Teachers
                </Text>
              )}
            </div>
          </Card>
        </Col>
        <Col xl={8} lg={12} md={24} sm={24} xs={24}>
          <Card
            bodyStyle={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: 10,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => list('participants')}
            style={{
              background: 'url(dashpics/participants.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'right',
              height: 170,
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <div style={{ marginTop: 100 }}>
              {!data ? (
                <div style={{ marginLeft: '50%' }}>
                  <div className="dot-pulse"></div>
                </div>
              ) : (
                <Text strong italic style={{ color: '#fff', fontSize: 25 }}>
                  {data?.data?.participants} Participants
                </Text>
              )}
            </div>
          </Card>
        </Col>
        <Col xl={8} lg={12} md={24} sm={24} xs={24}>
          <Card
            bodyStyle={{
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              padding: 10,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
            onClick={() => list('sessions')}
            style={{
              background: 'url(dashpics/sessions.png',
              // backgroundColor: "#d9d9d9",
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              objectFit: 'contain',
              height: 170,
              cursor: 'pointer',
              textAlign: 'center',
            }}
          >
            <div style={{ marginTop: 100, textAlign: 'center' }}>
              {!data ? (
                <div style={{ marginLeft: '50%' }}>
                  <div className="dot-pulse"></div>
                </div>
              ) : (
                <Text strong italic style={{ color: '#fff', fontSize: 25 }}>
                  {data?.data?.lessons} Sessions
                </Text>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};
