import {
  useGetIdentity,
  useList,
  useLogout,
  useNavigation,
} from "@pankod/refine-core";
import { useCallback, useEffect, useState } from "react";
import Pusher from "pusher-js";
import axios from "utils/axios";
import "./chat.css";

import { singleMsg, chatPrvw, active } from "interfaces";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timeAgo from "assets/timeAgo";

dayjs.extend(relativeTime);

const pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY!, {
  cluster: "mt1",
});

const filters: string[] = ["Support", "Bugs", "Costs", "Suggestions"];

export const Support: React.FC = () => {
  const { push } = useNavigation();
  const { data: user } = useGetIdentity();
  const { mutate: logout } = useLogout();

  /* in furure might need to use:->  user?.id */

  const adminAuthor = "62bc3d507056340db0f80ce2"; //jeff.james

  const [filter, setFilter] = useState<string>("Support");
  const [chatPreviews, setChatPreviews] = useState<chatPrvw[]>([]);

  const [activeRoom, setActiveRoom] = useState<active>({
    userName: "",
    profilePic: "",
    chatRoom: "",
  });

  const [chatRoomMsgs, setChatRoomMsgs] = useState<singleMsg[]>([]);
  const [currentMsg, setCurrentMsg] = useState<string>("");

  const {
    refetch: refetchChatRoomMsgs,
    isFetching,
    isFetched,
  } = useList({
    resource: "support",
    queryOptions: {
      onSuccess: (data: any) => {
        if (!data?.data) return;

        if (activeRoom.chatRoom === "") {
          setChatPreviews(
            [...data?.data].sort(
              (a, b) =>
                new Date(b.messages[0].time).getTime() -
                new Date(a.messages[0].time).getTime()
            )
          );
        } else {
          setChatRoomMsgs(data?.data?.messages);

          /* Set messages to "read:true" */
          // return;
          axios.patch(
            `support?chatRoom=${activeRoom?.chatRoom}&author=${adminAuthor}`
          );
        }
      },
    },
    config: {
      filters: [
        {
          field: "chatRoom",
          operator: "eq",
          value: activeRoom?.chatRoom,
        },
        {
          field: "filter",
          operator: "eq",
          value: filter,
        },
      ],
    },
  });

  const resortChatsPreview = useCallback(
    (newMsg: singleMsg) => {
      let leftSideChats = [...chatPreviews];

      let chatIndex = leftSideChats.findIndex(
        (ch) => ch.chatRoom === newMsg.chatRoom
      );

      if (chatIndex === -1) return;

      leftSideChats[chatIndex].messages[0].msg = newMsg.msg;
      leftSideChats[chatIndex].messages[0].time = newMsg.time;

      if (activeRoom.chatRoom !== newMsg.chatRoom) {
        leftSideChats[chatIndex].messages[0].read = false;
      }

      setChatPreviews(
        leftSideChats.sort(
          (a, b) =>
            new Date(b.messages[0].time).getTime() -
            new Date(a.messages[0].time).getTime()
        )
      );
    },
    [chatPreviews, activeRoom.chatRoom]
  );

  function hideUnreadCount(room: string) {
    let leftSideChats = [...chatPreviews];

    let chatIndex = leftSideChats.findIndex((ch) => ch.chatRoom === room);

    if (chatIndex === -1) return;
    leftSideChats[chatIndex].messages[0].read = true;

    setChatPreviews(
      leftSideChats.sort(
        (a, b) =>
          new Date(b.messages[0].time).getTime() -
          new Date(a.messages[0].time).getTime()
      )
    );
  }

  useEffect(() => {
    if (!user) {
      logout();
    }
    refetchChatRoomMsgs();
  }, [refetchChatRoomMsgs, activeRoom, filter, logout, user]);

  useEffect(() => {
    const channel = pusher.subscribe(activeRoom.chatRoom);
    const channel2 = pusher.subscribe("brand-new-msg");

    // updates chats
    channel.bind("chat-update", function (data: singleMsg) {
      if (String(data?.author) === adminAuthor) return;
      setChatRoomMsgs((list) => [...list, data]);

      /* Only resort chat prevw if is in same categoty filter */
      if (data?.chatRoom?.includes(filter)) resortChatsPreview(data);
    });

    // updates left-side preview of if any new chat from teacher
    channel2.bind("new-chat-update", function (data: singleMsg) {
      resortChatsPreview(data);
    });

    return () => {
      pusher.unsubscribe(activeRoom.chatRoom);
      pusher.unsubscribe("brand-new-msg");
      console.log("leaving");
    };
  }, [activeRoom, adminAuthor, resortChatsPreview, filter]);

  const onFilterClick = (f: string) => {
    setChatRoomMsgs([]);
    setActiveRoom({
      userName: "",
      profilePic: "",
      chatRoom: "",
    });
    setChatPreviews([]);
    setFilter(f);
  };

  const onUserClick = (chatRoom: string, uname: string, pic?: string) => {
    setChatRoomMsgs([]);
    setActiveRoom({
      userName: uname,
      profilePic: pic,
      chatRoom,
    });

    hideUnreadCount(chatRoom);
  };

  const sendMsg = async () => {
    if (currentMsg === "") return;

    let mesg = currentMsg;

    const messageData: singleMsg = {
      chatRoom: activeRoom.chatRoom,
      author: {
        _id: adminAuthor,
        userName: "Admin",
      },
      msg: mesg,
      time: new Date().toISOString(),
    };

    setChatRoomMsgs((list) => [...list, messageData]);
    setCurrentMsg("");

    resortChatsPreview(messageData);

    await axios.post("/support", messageData);
  };

  return (
    <div className='chat-container'>
      {/* Left Pane */}
      <div className='left'>
        <div className='left-top'>
          <div className='searchbar'>
            <svg
              width={16}
              height={16}
              fill='none'
              stroke='currentColor'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1}
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='m17 17 5 5m-2.5-11.25a8.75 8.75 0 1 1-17.5 0 8.75 8.75 0 0 1 17.5 0Z' />
            </svg>
            <input type='text' placeholder='Search...'></input>
          </div>
        </div>

        <div className='left-top-filters'>
          {filters.map((f) => {
            const isSelected = f === filter;
            return (
              <div
                key={f}
                onClick={() => onFilterClick(f)}
                style={{
                  backgroundColor: isSelected ? "#1f384b" : "",
                  color: isSelected ? "#fff" : "",
                }}
              >
                {f}
              </div>
            );
          })}
        </div>

        <div className='discussions'>
          {isFetching && !chatPreviews?.length && (
            <div style={{ position: "absolute", top: "50%", left: "32%" }}>
              Loading chats...
            </div>
          )}

          {!isFetching && isFetched && !chatPreviews?.length && (
            <div style={{ position: "absolute", top: "50%", left: "32%" }}>
              No chats
            </div>
          )}

          {chatPreviews?.map((chat, x) => {
            const lastMsg = chat?.messages[0];
            const alreadyInRoom = activeRoom?.chatRoom === chat?.chatRoom;

            const seeUnread =
              !lastMsg?.read && lastMsg?.author?._id !== user.id;

            return (
              <div
                key={x}
                className={`discussion ${alreadyInRoom && "message-active"}`}
                style={{ position: "relative" }}
                onClick={() => {
                  if (alreadyInRoom) return;
                  onUserClick(
                    chat?.chatRoom,
                    lastMsg?.author?.userName,
                    lastMsg?.author?.profilePic
                  );
                }}
              >
                <div
                  className='photo'
                  style={{
                    backgroundImage: `url(${lastMsg?.author?.profilePic})`,
                  }}
                >
                  {/* <div className='online'></div> */}
                </div>

                <div className='desc-contact'>
                  <p className='name'>{lastMsg?.author?.userName}</p>
                  <p className='message'>{lastMsg?.msg}</p>
                </div>

                <div className='timer'>
                  {timeAgo(lastMsg?.time)?.slice(0, -3) || "now"}
                </div>

                {/* Show unread id last msg not read & is not admin's */}
                {seeUnread && <div className='unread'>new</div>}
              </div>
            );
          })}
        </div>
      </div>

      {/* Right Pane */}
      <div className='right'>
        <div className='header-chat'>
          <div>
            <div
              style={{
                display: activeRoom?.chatRoom === "" ? "none" : "flex",
                flexDirection: "row",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <div
                className='photo'
                style={{
                  backgroundImage: `url(${activeRoom?.profilePic})`,
                }}
              >
                {/* <div className='online'></div> */}
              </div>
              <div className='name'>{activeRoom?.userName}</div>
            </div>
          </div>

          <div>
            <b
              onClick={(): void => {
                push("/");
              }}
              style={{ color: "white", cursor: "pointer" }}
            >
              Back to dashboard
            </b>
          </div>
        </div>

        <div className='messages-chat'>
          {isFetching && activeRoom?.chatRoom !== "" && (
            <div style={{ position: "absolute", top: "50%", left: "40%" }}>
              Loading messages...
            </div>
          )}

          {[...chatRoomMsgs].map((msg, i) => {
            const isAdmin = msg?.author?._id === adminAuthor;
            const profPic = activeRoom?.profilePic;

            const prevMsgIsMine =
              chatRoomMsgs[i - 1]?.author?._id ===
                chatRoomMsgs[i]?.author?._id ||
              chatRoomMsgs[i - 1]?.author?.userName ===
                chatRoomMsgs[i]?.author?.userName;

            return (
              <div
                key={i}
                className={
                  !isAdmin ||
                  chatRoomMsgs[i - 1]?.author?._id ===
                    chatRoomMsgs[i]?.author?._id
                    ? "message"
                    : "message text-only"
                }
              >
                <div
                  className='photo'
                  style={{
                    display: isAdmin || prevMsgIsMine ? "none" : "",
                    backgroundImage: `url(${profPic})`,
                  }}
                >
                  {/* <div className='online'></div> */}
                </div>
                <div
                  style={{
                    display: !prevMsgIsMine ? "none" : "",
                    marginLeft: prevMsgIsMine ? 45 : 0,
                  }}
                />
                <div className={isAdmin ? "response" : ""}>
                  <p className='text'>
                    {" "}
                    {msg?.msg}
                    <span>
                      {new Date(msg?.time).getTime() >
                      new Date(new Date().setHours(0, 0)).getTime()
                        ? dayjs(msg?.time).format("hh:mm A")
                        : dayjs(msg?.time).format("DD/MM/YYYY")}
                    </span>
                  </p>
                </div>
              </div>
            );
          })}
        </div>

        <div className='input-area'>
          <div>
            <svg
              width={28}
              height={28}
              fill='none'
              stroke='#fff'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={1}
              style={{ transform: "rotate(45deg)", cursor: "pointer" }}
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M7.5 6.75V18c0 3 2.25 4.5 4.5 4.5s4.5-1.5 4.5-4.5V4.5c0-2.25-1.5-3-3-3s-3 .75-3 3v12.75c0 .75.75 1.5 1.5 1.5s1.5-.75 1.5-1.5V6.75' />
            </svg>
          </div>{" "}
          <input
            type='text'
            disabled={activeRoom?.chatRoom === ""}
            placeholder='Type a message...'
            value={currentMsg}
            onChange={(event) => {
              setCurrentMsg(event.target.value);
            }}
            onKeyPress={(event) => {
              event.key === "Enter" && sendMsg();
            }}
          />
          <div>
            <svg
              onClick={() => sendMsg()}
              width={28}
              height={28}
              fill='#fff'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
              style={{ cursor: "pointer" }}
            >
              <path d='M20.782 3.218a.75.75 0 0 0-.787-.172l-16.5 6a.75.75 0 0 0 0 1.402l7.2 2.88 2.88 7.2a.75.75 0 0 0 .675.473.75.75 0 0 0 .69-.495l6-16.5a.75.75 0 0 0-.158-.788ZM14.25 18.151l-2.093-5.25 3.593-3.593-1.058-1.057-3.622 3.622-5.22-2.122 13.147-4.748-4.747 13.148Z' />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};
// https://github.com/onedebos/pusher-chat-app
