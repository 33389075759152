import { HttpError } from "@pankod/refine-core";
import axios, { AxiosRequestConfig } from "axios";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  timeout: 10000,
});

axiosInstance.interceptors.request.use((request: AxiosRequestConfig) => {
  const user = JSON.parse(localStorage.getItem("tnc-auth") || "{}");
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${user.token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${user.token}`,
    };
  }

  return request;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.data.status === "error") {
      return Promise.reject(new Error(response.data.message));
    } else {
      return response;
    }
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export default axiosInstance;
