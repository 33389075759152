import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  Space,
  ShowButton,
  EmailField,
  DeleteButton,
} from "@pankod/refine-antd";
import { IRevenue } from "interfaces";
import { cutText } from "assets/helpers";

export const ContactList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IRevenue>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List
      title='Contacts'
      pageHeaderProps={{
        extra: null,
      }}
    >
      <Table {...tableProps} rowKey='_id'>
        <Table.Column
          dataIndex='name'
          key='name'
          title='Name'
          render={(value) => <TextField value={value} />}
        />

        <Table.Column
          dataIndex='email'
          key='email'
          title='Email'
          render={(value) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex='message'
          key='message'
          title='Message'
          render={(value) => <TextField value={cutText(value, 20)} />}
        />

        <Table.Column<any>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              <ShowButton hideText size='small' recordItemId={record._id} />
              <DeleteButton hideText size='small' recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
