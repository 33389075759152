import { AuthProvider } from "@pankod/refine-core";
// import axios from "axios";
import axios from "./utils/axios";

export const TOKEN_KEY = "tnc-auth";

export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await axios
      .post("/auth/login", {
        username: username,
        password: password,
        userType: "Admin",
      })
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem(TOKEN_KEY, JSON.stringify(response.data));
          return Promise.resolve("Login Successful!");
        } else {
          return Promise.reject(new Error(response.data.message));
        }
      })
      .catch(() => {
        return Promise.reject({
          name: "Login Failed!",
          message: "Admin user not found",
        });
      });
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.statusCode === 401) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }
    let user = JSON.parse(token);

    return Promise.resolve({
      id: user.id,
      name: user.name,
      avatar: "/avatar.svg",
    });
  },
};
