import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  Layout,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/styles.min.css";
import routerProvider from "@pankod/refine-react-router-v6";
import dataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "authProvider";
import axiosInstance from "utils/axios";
import { DashboardPage } from "pages/dashboard";
import {
  TeacherList,
  TeacherCreate,
  TeacherEdit,
  TeacherShow,
} from "pages/teachers";
import {
  ParticipantList,
  ParticipantCreate,
  ParticipantEdit,
  ParticipantShow,
} from "pages/participants";
import { PaymentsList } from "pages/payments";
import {
  SessionsList,
  SessionsCreate,
  SessionsEdit,
  SessionsShow,
} from "pages/sessions";
import { Title } from "components/sider";
import { LoginPage } from "./pages/login";
import { FlagList } from "pages/flags";
import { RevenueList, RevenueShow } from "pages/revenue";
import {
  AreaChartOutlined,
  CalendarOutlined,
  DollarOutlined,
  FlagOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Support } from "pages/custom";
import { Header } from "components/header";
import { ContactList, ContactShow } from "pages/contacts";

function App() {
  return (
    <Refine
      notificationProvider={notificationProvider}
      authProvider={authProvider}
      Layout={Layout}
      DashboardPage={DashboardPage}
      ReadyPage={ReadyPage}
      Header={Header}
      catchAll={<ErrorComponent />}
      // routerProvider={routerProvider}
      routerProvider={{
        ...routerProvider,
        routes: [
          {
            element: <Support />,
            path: "support",
          },
        ],
      }}
      dataProvider={dataProvider(
        String(process.env.REACT_APP_BASE_URL),
        axiosInstance
      )}
      Title={Title}
      LoginPage={LoginPage}
      resources={[
        {
          name: "teachers",
          list: TeacherList,
          create: TeacherCreate,
          edit: TeacherEdit,
          show: TeacherShow,
          icon: <UserOutlined />,
        },
        {
          name: "sessions",
          list: SessionsList,
          create: SessionsCreate,
          edit: SessionsEdit,
          show: SessionsShow,
          icon: <CalendarOutlined />,
        },
        {
          name: "participants",
          list: ParticipantList,
          create: ParticipantCreate,
          edit: ParticipantEdit,
          show: ParticipantShow,
          icon: <UsergroupAddOutlined />,
        },
        {
          name: "flags",
          list: FlagList,
          icon: <FlagOutlined />,
        },
        {
          name: "payments",
          list: PaymentsList,
          icon: <DollarOutlined />,
        },
        {
          options: { label: "Revenue" },
          name: "revenue",
          list: RevenueList,
          show: RevenueShow,
          icon: <AreaChartOutlined />,
        },
        {
          options: { label: "Contacts" },
          name: "contacts",
          list: ContactList,
          show: ContactShow,
          icon: <UsergroupAddOutlined />,
        },
      ]}
    />
  );
}

export default App;
