import { UserOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  Col,
  Upload,
  Space,
  Avatar,
  Typography,
} from "@pankod/refine-antd";

const { Text } = Typography;

export const PersonalInfo: React.FC = () => {
  return (
    <Row gutter={16}>
      <Col span={6}>
        <Form.Item>
          <Form.Item
            name="avatar"
            valuePropName="fileList"
            // getValueFromEvent={getValueFromEvent}
            noStyle
          >
            <Upload.Dragger
              name="file"
              // action={`${apiUrl}/media/upload`}
              listType="picture"
              maxCount={1}
              multiple
              style={{
                border: "none",
                width: "100%",
                background: "none",
              }}
            >
              <Space direction="vertical" size={2}>
                <Avatar
                  style={{
                    width: "100%",
                    height: "100%",
                    // maxWidth: "200px",
                  }}
                  src="https://example.admin.refine.dev/images/user-default-img.png"
                  alt="Store Location"
                  icon={<UserOutlined />}
                />
                <Text
                  style={{
                    fontWeight: 800,
                    fontSize: "16px",
                    marginTop: "8px",
                  }}
                >
                  Display Photo
                </Text>
                <Text style={{ fontSize: "12px" }}>Min 192X192 px</Text>
              </Space>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
      </Col>

      <Col span={18}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Username"
              name="User Name"
              rules={[
                {
                  // required: true,
                  message: "Username is required",
                },
              ]}
            >
              <Input placeholder="Teacher's Username" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  // required: true,
                  message: "Email is required",
                  type: "email",
                },
              ]}
            >
              <Input type="email" placeholder="user@email.com" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Display Name"
              name="title"
              rules={[
                {
                  // required: true,
                  message: "Title is required",
                },
              ]}
            >
              <Input placeholder="Teacher, doctor, linguist e.t.c"/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Settlments Account"
              name="settlements"
              rules={[
                {
                  // required: true,
                  message: "Settlements is required",
                  type: "email",
                },
              ]}
            >
              <Input type="email" placeholder="coach@paypal.com" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
