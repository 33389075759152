import { IResourceComponentsProps } from "@pankod/refine-core";
import {
  List,
  Table,
  TextField,
  useTable,
  DateField,
  Space,
  DeleteButton,
  EmailField,
} from "@pankod/refine-antd";
import { IFlag } from "interfaces";

export const FlagList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IFlag>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  return (
    <List>
      <Table {...tableProps} rowKey='_id'>
        <Table.Column
          key='lessonId'
          title='Title'
          render={(value) => <TextField value={value?.lessonId?.title} />}
        />
        <Table.Column
          dataIndex='reason'
          key='reason'
          title='Reason'
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex='email'
          key='email'
          title='FlaggedBy'
          render={(value) => <EmailField value={value} />}
        />
        <Table.Column
          dataIndex='createdAt'
          key='createdAt'
          title='Flagged On'
          render={(value) => <DateField value={value} format='LLL' />}
        />

        <Table.Column<any>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              <DeleteButton hideText size='small' recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
