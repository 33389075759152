export default function timeAgo(input: any) {
  const date = input instanceof Date ? input : new Date(input);
  const formatter = new Intl.RelativeTimeFormat("en", { style: "short" });
  interface Ranges {
    years: number;
    months: number;
    weeks: number;
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  }

  let ranges: Ranges = {
    years: 3600 * 24 * 365,
    months: 3600 * 24 * 30,
    weeks: 3600 * 24 * 7,
    days: 3600 * 24,
    hours: 3600,
    minutes: 60,
    seconds: 1,
  };

  const secondsElapsed = (date.getTime() - Date.now()) / 1000;
  for (let key in ranges) {
    if (ranges[key as keyof Ranges] < Math.abs(secondsElapsed)) {
      const delta = secondsElapsed / ranges[key as keyof Ranges];
      return formatter.format(Math.round(delta), key as keyof Ranges);
    }
  }
}
