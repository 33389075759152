import { IResourceComponentsProps } from "@pankod/refine-core";

import {
  List,
  Table,
  useTable,
  Space,
  Image,
  TextField,
  ShowButton,
  DeleteButton,
  getDefaultSortOrder,
  UrlField,
  Tag,
  EditButton,
} from "@pankod/refine-antd";
import axios from "axios";

import { ISession } from "interfaces";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import timezones from "assets/timezones";

dayjs.extend(utc);
dayjs.extend(timezone);

const url = process.env.REACT_APP_MEDIA_URL;

export const SessionsList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ISession>({});

  return (
    <List
      title='All Sessions'
      pageHeaderProps={{
        subTitle: null,
        extra: "",
      }}
    >
      <Table
        {...tableProps}
        expandable={{
          expandedRowRender: expandedRowRender,
        }}
        rowKey='title'
      >
        {" "}
        <Table.Column
          key='thumbnail'
          dataIndex='thumbnail'
          render={(value) => <Image width={64} height={36} src={value} />}
        />
        <Table.Column
          key='title'
          dataIndex='title'
          title='Title'
          render={(value) => {
            return value;
          }}
        />
        <Table.Column
          key='author'
          dataIndex='author'
          title='Instructor'
          render={(value) => {
            if (value?.userName)
              return (
                <UrlField
                  value={`teachers/show/${value?._id}`}
                  children={value?.userName}
                />
              );
            return null;
          }}
        />
        {/* <Table.Column<ISession>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              <DeleteButton
                hideText
                size='small'
                resourceName='sessions'
                recordItemId={record?._id}
              />{" "}
            </Space>
          )}
        /> */}
      </Table>
    </List>
  );
};

const SessionsWithSameTitle: React.FC<{ record: ISession }> = ({ record }) => {
  const { tableProps, sorter } = useTable<ISession>({
    resource: "sessions",
    permanentFilter: [
      {
        field: "title",
        operator: "eq",
        value: record?.title,
      },
    ],
    initialSorter: [
      {
        field: "createdAt",
        order: "desc",
      },
    ],
    initialPageSize: 5,
    syncWithLocation: false,
  });

  return (
    <List
      title={""}
      pageHeaderProps={{
        subTitle: null,
        extra: "",
      }}
      createButtonProps={undefined}
    >
      <Table {...tableProps} rowKey='_id'>
        <Table.Column
          key='dateTime'
          title='Dates for this session'
          render={(value) => {
            return value?.dateTime ? (
              dayjs(value.dateTime).tz(value.timeZone).format("lll")
            ) : (
              <Tag color={"success"}>
                Recurring on {value?.recurringDetails?.day}s
              </Tag>
            );
          }}
          defaultSortOrder={getDefaultSortOrder("dateTime", sorter)}
          sorter
        />
        <Table.Column
          key='timeZone'
          title='TimeZone'
          render={(value) => {
            const tz = timezones.find((tz) => tz.id === value.timeZone);

            return <TextField value={tz?.name} />;
          }}
        />

        <Table.Column<ISession>
          title='Actions'
          dataIndex='actions'
          render={(_, record) => (
            <Space>
              <ShowButton
                hideText
                size='small'
                resourceName='sessions'
                recordItemId={record?._id}
              />
              <EditButton
                hideText
                size='small'
                resourceName='sessions'
                recordItemId={record?._id}
              />
              <DeleteButton
                hideText
                size='small'
                resourceName='sessions'
                recordItemId={record?._id}
                onSuccess={async ({
                  data: { canDelMedia, thumbnail, videoPitch },
                }) => {
                  if (!canDelMedia) return;

                  let thumbToDel = thumbnail?.split("/").pop();
                  let pitchToDel = videoPitch?.split("/").pop();

                  await axios.delete(
                    `${url}/media/${thumbToDel}?folderName=thumbnails`
                  );

                  await axios.delete(
                    `${url}/media/${pitchToDel}?folderName=pitches`
                  );
                }}
              />{" "}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

const expandedRowRender = (record: ISession) => {
  return <SessionsWithSameTitle record={record} />;
};
