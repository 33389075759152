import { IResourceComponentsProps } from "@pankod/refine-core";
import { Show } from "@pankod/refine-antd";

// import { IUser } from "interfaces";

export const ParticipantShow: React.FC<IResourceComponentsProps> = () => {
  // const { queryResult } = useShow<IUser>();
  // const { data, isLoading } = queryResult;

  return (
    <Show
    // isLoading={isLoading}
    >
      {/* <Title level={5}>Title</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>Status</Title>
      <Text>
        <Tag>{record?.status}</Tag>
      </Text>

      <Title level={5}>Category</Title>
      <Text>{categoryData?.data.title}</Text>

      <Title level={5}>Content</Title>
      <MarkdownField value={record?.content} /> */}
    </Show>
  );
};
